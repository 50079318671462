import request from '../utils/request'

/**
 * 附近的人
 * @param params
 */
export const queryRecentlyUser = (params) => {
  let url = '/playerLogin/nearby'
  return request('POST', url, params, false, false)
}

/**
 * 修改个人信息
 * @param params
 */
export const editUser = (params) => {
  return request('POST', '/playerBase/update', params, true, false)
}

/**
 * 个人中心修改背景图片
 * @param params
 */
export const updateBackgroundPhoto = (params) => {
  return request('POST', '/playerBase/updateBackgroundPhoto', params, true, false)
}

/**
 * 修改个人相册
 * @param params
 */
export const editUserPhotos = (params) => {
  return request('POST', '/playerBase/updatePhotos', params, true, false)
}

/**
 * 根据用户ID查询个人详细信息
 * @param params
 */
// export const queryUserInfoById = (params) => {
//   return request('POST', '/playerBase/detail', params, true, false)
// }

export const queryUserInfoById = (params) => {
  return request('POST', '/commonBase/userDetail', params, true, false)
}
/**
 * 根据用户名查询用户列表
 * @param params
 */
export const queryUserByName = (params) => {
  return request('POST', '/playerLogin/search', params, true, true)
}

/**
 * 修改性别
 * @param params
 */
export const updateSex = (params) => {
  return request('POST', '/playerBase/changeSex', params, true, false)
}

/**
 * 修改联系方式
 * @param params 
 * @returns 
 */
export const updateContact = (params) => {
  return request('POST', '/playerBase/updateContact', params, true, false)
}

/**
 * 查询我的邀请人数
 * @returns {Promise | Promise<unknown>}
 */
export const queryInviteInfo = () => {
  return request('POST', '/playerBase/inviteInfo', null, true, false)
}

/**
 * 绑定邀请人
 * @param params
 */
export const updateInviteInfo = (params) => {
  return request('POST', '/playerBase/inviteSubmit', params, true, false)
}

/**
 * 获取奖励记录
 * @param params
 */
export const queryRerowdList = (params) => {
  return request('POST', '/playerBase/rewardList', params, true, false)
}
/**
 * 获取可提取奖励金额
 * 
 */
export const queryTotalReward = () => {
  return request('POST', '/playerBase/getTotalReward', null, true, false)
}

/**
 * 申请提现
 * @param params
 */
export const applyWithdraw = (params) => {
  return request('POST', '/playerBase/withdraw', params, true, false)
}
/**
 * 获取提现记录
 * @param params
 */
export const queryWithdrawList = (params) => {
  return request('POST', '/playerBase/getWithdrawList', params, true, false)
}

/**
 * 获取提现开关
 * @param params
 */
export const getWithdrawSwitch = (params) => {
  return request('POST', '/commonBase/getBaseParam', params, false, false)
}

/**
 * 上报认证视频文件路径
 * @param params
 */
export const reportVideoPath = (params) => {
  return request('POST', '/playerBase/submitAudit', params, true, false)
}

/**
 * 上报 查看/复制 社交账号
 * @param params 10: 查看联系方式 20: 复制联系方式
 */
export const reportSocialAccount = (params) => {
  return request('POST', '/playerAction/report', params, true, false)
}

/**
 * 修改密码
 * @param params
 */
export const updatePassword = (params) => {
  return request('POST', '/playerBase/editPassword', params, true, false)
}
