<template>
  <div class="edit-page pt-nav">
    <Header :title="$t('userDetail.profile')" :bgColor="'#000000'" :subTitleBgColor="'#000000'"
      :subTitle="$t('userDetail.editProfile')">
      <ChangeAvatar :headPhoto="userInfoVo.headPhoto" :setHeadPhoto="setHeadPhoto" :bgColor="'#000000'" />
      <ChangeBg :userId="userInfo.userId" />
    </Header>
    <div class="info-wrap">
      <div class="info-key-box" v-for="(item, index) in infoKeyList" :key="item.key" @click="handleClick(item, index)">
        <div class="key"><span class="require-flag" v-if="item.require">*</span>{{ formatName(item) }}</div>
        <div class="value">
          <span>{{ item.value }}</span>
          <VanIcon class="icon" name="arrow" />
        </div>
      </div>
      <div class="save-btn" @click="handleSave">{{ $t('common.save') }}</div>
    </div>

    <!--文本输入-->
    <TextInput v-model="popInputShow" :title="inputObj.title" :inputType="inputObj.type" :initMsg="inputObj.value"
      :placeholder="inputObj.placeholder" :maxlength="inputObj.maxlength" :rows="inputObj.rows"
      @close="() => popInputShow = false" @getMessage="getInputValue" />

    <DatePicker v-if="showBirthday" @getSelected="getInputValue" @close="() => showBirthday = false" />

    <!--身高体重-->
    <HwPicker v-model="showHeightWeight" @getSelected="getInputValue" @close="() => showHeightWeight = false" />

    <!--标签-->
    <LabelPop v-model="showLabel" :title="LableObj.title" :labels="LableObj.labels" :limit="LableObj.limit"
      @getSelectedList="getLabelSelect" @close="() => showLabel = false" />

    <!-- 年龄 -->
    <AgePicker v-model="showAge" @getSelected="getInputValue" @close="() => showAge = false" />

    <router-view class="child-router-view"></router-view>

  </div>
</template>

<script>
import bus from '@/utils/bus'
import { mapActions, mapState } from 'vuex'
import Header from '@/components/Header.vue';
import ChangeBg from '@/components/ChangeBg.vue';
import ChangeAvatar from '@/components/ChangeAvatar.vue';
import TextInput from '../../components/user/TextInput.vue'
import DatePicker from '../../components/user/DatePicker.vue'
import HwPicker from '../../components/user/HwPicker.vue'
import LabelPop from '../../components/user/LabelPop.vue'
import AgePicker from '../../components/user/AgePicker.vue'
import { editUser } from '@/service/user'
import { getLables } from '@/service/base'

export default {
  name: 'PersonalEdit',
  components: {
    Header,
    ChangeBg,
    ChangeAvatar,
    TextInput,
    DatePicker,
    HwPicker,
    LabelPop,
    AgePicker
  },
  data() {
    return {
      avatarList: [],
      popInputShow: false, // 文本输入
      showBirthday: false, // 日期
      showHeightWeight: false, // 身高体重
      showLabel: false,
      showAge: false, // 年龄
      selectIndex: 0,
      selectKey: '',
      infoKeyList: [
        {
          key: 'nickname',
          name: '昵称',
          enName: 'Name',
          value: '',
          type: 'text',
          require: true
        },
        {
          key: 'telegram',
          name: 'telegram',
          enName: 'telegram',
          value: '',
          type: 'text',
          require: false
        },
        {
          key: 'wechat',
          name: '微信',
          enName: 'wechat',
          value: '',
          type: 'text',
          require: false
        },
        {
          key: 'position',
          name: '位置',
          enName: 'Location',
          value: '',
          type: 'address',
          require: true,
          areaLevel: 0,
        },
        {
          key: 'age',
          name: '年龄',
          enName: 'Age',
          value: '',
          type: 'AgePicker'
        },
        {
          key: 'heightAndweight',
          name: '身高体重',
          enName: 'Height & Weight',
          value: '',
          type: 'HwPicker'
        },
        {
          key: 'description',
          name: '自我介绍',
          enName: 'Self Description',
          value: '',
          type: 'textarea',
          maxlength: 150,
          rows: 5
        },
        {
          key: 'job',
          name: '职业',
          enName: 'Career',
          value: '',
          type: 'labelSelect',
          limit: 1
        },
        {
          key: 'temperament',
          name: '气质类型',
          enName: 'Temperament Type',
          value: '',
          type: 'labelSelect',
          limit: 3
        },
        {
          key: 'oftenPosition',
          name: '经常出没',
          enName: 'I always go',
          value: '',
          type: 'address',
          areaLevel: 1,
          multiple: true,
          limit: 3
        }
      ],
      inputObj: {
        key: '',
        title: '',
        value: '',
        placeholder: '',
        inputType: 'text',
        maxlength: 30,
        rows: 1
      },
      LableObj: {
        title: '',
        labels: [],
        limit: 1
      },
      labelLabels: [], // 关于我标签
      jobLabels: [],  // 行业标签
      temperamentLabels: [], // 气质类型标签
      heightAndweight: [], // 身高体重
      position: [],
      oftenPosition: [],
      userInfoVo: {},
      isMan: false
    }
  },
  computed: {
    ...mapState(['lang', 'userInfo'])
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    init() {
      this.isMan = this.$gbUtils.isMan()
      if (this.isMan) {
        this.infoKeyList = [
          {
            key: 'nickname',
            name: '昵称',
            enName: 'Name',
            value: '',
            type: 'text',
            require: true
          }
        ]
      } else {
        this.getLabelList()
      }
      this.getPersonInfo()
    },
    formatName(item) {
      if (this.lang === 'EN') return item.enName
      return item.name
    },
    async getLabelList() {
      const resp = await getLables('sc_label_job')
      if (!resp.success) return
      this.jobLabels = resp.data

      const resp1 = await getLables('sc_label_temperament')
      if (!resp1.success) return
      this.temperamentLabels = resp1.data
    },
    getPersonInfo() {
      this.userInfoVo = this.userInfo
      this.formatData()
    },
    formatData() {
      if (this.userInfoVo.height) {
        this.userInfoVo.heightAndweight = `${this.userInfoVo.height}cm/${this.userInfoVo.weight}kg`
      }
      if (this.userInfoVo.location) {
        this.userInfoVo.position = this.$gbUtils.getAllPositionName(this.userInfoVo.location)
      }
      if (this.userInfoVo.often) {
        this.userInfoVo.oftenPosition = this.userInfoVo.often.split(',').join('、')
      }
      this.infoKeyList.forEach(item => {
        item.value = this.userInfoVo[item.key]
      })
    },
    handleClick(item, index) {
      this.selectIndex = index
      this.selectKey = item.key
      const name = this.formatName(item)
      if (item.type === 'text' || item.type === 'textarea') {
        this.inputObj = {
          key: item.key,
          title: name,
          value: item.value,
          placeholder: this.$t('common.input') + `${name}`,
          type: item.type,
          rows: item.rows || 1,
          maxlength: item.maxlength || 30
        }
        this.popInputShow = true
      } else if (item.type === 'DatePicker') {
        this.showBirthday = true
      } else if (item.type === 'HwPicker') {
        this.showHeightWeight = true
      } else if (item.type === 'AgePicker') {
        this.showAge = true
      } else if (item.type === 'address') {
        this.eidtLocation(item.key, item.areaLevel, item.multiple, item.limit)
      } else if (item.type === 'labelSelect') {
        this.LableObj = {
          key: item.key,
          title: name,
          limit: item.limit,
          labels: this.$data[`${item.key}Labels`]
        }
        this.showLabel = true
      }
    },
    eidtLocation(addressKey, areaLevel = 0, multiple = false, maxCount = 3) {
      const path = `/personalEdit/editAddress?addressKey=${addressKey}&areaLevel=${areaLevel}&multiple=${multiple}&maxCount=${maxCount}`
      this.$gbUtils.handleToPage(path)
    },
    // 获取输入框的值
    getInputValue(val) {
      if (this.selectKey === 'heightAndweight') {
        this.heightAndweight = val
        this.infoKeyList[this.selectIndex].value = `${val[0]}/${val[1]}`
        this.userInfoVo.height = val[0].substring(0, 3)
        this.userInfoVo.weight = val[1].substring(0, 2)
        return
      } if (this.selectKey === 'age') {
        return this.infoKeyList[this.selectIndex].value = val.join()
      }
      this.infoKeyList[this.selectIndex].value = val
    },
    getLabelSelect(val) {
      if (val.length === 0) return
      this.infoKeyList[this.selectIndex].value = val.join()
    },
    setHeadPhoto(headPhoto) {
      this.userInfoVo.headPhoto = headPhoto;
    },
    async handleSave() {
      if (!this.userInfoVo.headPhoto) {
        return this.$toast(this.$t('userDetail.headPhotoNotEmpty'))
      }
      for (let i = 0; i < this.infoKeyList.length; i++) {
        const item = this.infoKeyList[i]
        if (item.require && !item.value) {
          return this.$toast(`${item.name} ${this.$t('common.notEmpty')}`)
        }
        this.userInfoVo[item.key] = item.value
      }

      if (!this.isMan) {
        const telegram = this.userInfoVo.telegram
        const wechat = this.userInfoVo.wechat
        if (this.$gbUtils.isEmptyString(telegram) && this.$gbUtils.isEmptyString(wechat)) {
          return this.$toast(this.$t('userDetail.tgAndWechatNotEmpty'))
        }
      }
      const resp = await editUser(this.userInfoVo)
      if (!resp.success) return
      await this.changeUserInfo(this.userInfoVo)
      this.$toast(this.$t('common.editSuccess'))
      setTimeout(() => {
        this.$router.go(-1)
      }, 150);
    }
  },
  mounted() {
    this.init()
    bus.$on('getSelectAreas', (key, data) => {
      this.$data[key] = data
      if (key === 'position') {
        const locationName = data.province.name + '/' + data.city.name + '/' + data.area.name
        this.infoKeyList[this.selectIndex].value = locationName
        this.userInfoVo.location = data.area.code
        this.userInfoVo.locationName = locationName
      }
      if (key === 'oftenPosition') {
        let cityName = data.map(item => {
          return item.city.name
        })
        this.infoKeyList[this.selectIndex].value = cityName.join('、')
        this.userInfoVo.often = cityName.join(',')
        this.userInfoVo.oftenList = cityName
      }
    })
  },
  watch: {
    lang(oldValue, newValue) {
      console.log('oldLang,newLang', oldValue, newValue)
    }
  }
}
</script>

<style scoped lang="scss">
.back-box {
  background: #000;
}

.edit-page {
  .info-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 120px;
  }

  .key {
    min-width: 65px;
  }

  .require-flag {
    color: #EF08E4;
  }

  .save-btn {
    margin: 30px auto;
    width: 200px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    background: #8A73D0;
  }
}

.cropper-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
    width: 100%;
  }

  /deep/.cropper-view-box {
    border-radius: 100%;
  }
}
</style>
