<template>
  <div class="edit-bg-img">
    <img src="@/assets/img/camera.png" alt="">
    <span>更换背景</span>
    <input type="file" @change="handleModifyAvatar">

    <div class="cropper-bg-box" v-show="showCropper">
      <div class="box">
        <vueCropper ref="cropper" :centerBox="option.centerBox" :autoCrop="option.autoCrop" :fixed="option.fixed"
                    :img="option.img" :outputSize="option.outputSize" :canMoveBox="option.canMoveBox"
                    :outputType="option.outputType">
        </vueCropper>
      </div>
      <div class="save-btn" @click="cropImage">更换背景图片</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {VueCropper} from 'vue-cropper'
import {updateAgentBackgroundImage} from '@/service/agent'
import {updateBackgroundPhoto} from '@/service/user'
import {uploadFile} from '@/service/upload'
import * as imageConversion from 'image-conversion';

export default {
  name: "headerChangeBg",
  data() {
    return {
      showCropper: false,
      option: {
        outputSize: 1,
        centerBox: true,
        autoCrop: true,
        fixed: false,
        fixedNumber: [4,3],
        canMoveBox: false,
        fixedBox: true,
        img: '',
        outputType: 'png',
      }
    };
  },
  components: { VueCropper },
  props: {
    userId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    
    async onEditBgImg(event) {
      if (!this.userId) {
        this.$toast('params error')
        return
      }
      const file = event.target.files[0];
      let compressFile = file;
      if (file.size / 1024 / 1024 > 2) {
        const res = await imageConversion.compress(file, 0.6);
        compressFile = new File([res], file.name);
      }
      console.log(compressFile)
      const uploadRes = await uploadFile(compressFile, 'image')
      console.log('uploadRes==>', uploadRes)
      if (uploadRes.success) {
        let bgImgParams = {
          backgroundPhoto: uploadRes.s3Key
        }
        await this.updateBgImg(bgImgParams)
      }
    },
    async updateBgImg(params) {
      // 修改用户自己的背景图片
      if (this.userId === this.userInfo.userId) {
        console.log('update user background image')
        const resp = await updateBackgroundPhoto(params)
        if (resp.code === 200) {
          this.$toast('更新成功')
          this.userInfo.backgroundPhoto = params.backgroundPhoto
          await this.changeUserInfo(this.userInfo)
        }
        
      } else {
        // 修改代理下资源的背景图片
        params.userId = this.userId
        console.log('update agent is resource background image')
        const resp = await updateAgentBackgroundImage(params)
        if (resp.code === 200) {
          this.$toast('更新成功')
        }
      }
    },
    handleModifyAvatar(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        this.option.img = base64String;
        this.showCropper = true;
      };
      reader.readAsDataURL(file);
    },
    cropImage() {
      this.$refs.cropper.startCrop();
      this.$refs.cropper.getCropBlob(async (data) => {
        this.option.img = '';
        this.showCropper = false;
        this.$refs.cropper.stopCrop();
        const file = this.blobToFile(data, 'background.png');
        let compressFile = file;
        if (file.size / 1024 / 1024 > 0.3) {
          const res = await imageConversion.compress(file, 0.6);
          compressFile = new File([res], file.name, { type: 'image/png' });
        }
        const resp = await uploadFile(compressFile, 'image')
        if (resp.success) {
          let bgImgParams = {
            backgroundPhoto: resp.s3Key
          }
          await this.updateBgImg(bgImgParams)
        }
      })
    },
    blobToFile(blob, fileName) {
      return new File([blob], fileName, {type: blob.type});
    }
  },
  created() { },
  mounted() { },
};
</script>


<style lang="scss" scoped>
.edit-bg-img {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100px;
  height: 32px;
  background: rgba(82, 71, 116, 0.3);
  border-radius: 16px;
  justify-content: center;
  right: 20px;
  top: 60px;

  img {
    width: 16px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  .cropper-bg-box {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: #000;
    display: flex;
    flex-direction: column;

    .box {
      flex: 1;
      width: 100%;
    }

    ///deep/.cropper-view-box {
    //  border-radius: 100%;
    //}

    .save-btn {
      width: 200px;
      height: 38px;
      background: #8A73D0;
      border-radius: 4px;
      text-align: center;
      line-height: 38px;
      font-size: 13px;
      font-weight: 600;
      margin: 30px auto;
    }
  }
}
</style>
