<template>
  <van-popup v-model="show" position="center" :overlayStyle="{ background: 'rgba(123,101,128,0.7)' }"
  :style="{ width: '85%', maxHeight: '80%', background: '#2F2330', padding: '0 10px' }" closeable @close='closePop'
  round>

  <div class="picker-wrap">
    <div class="picker-title">选择生日</div>
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      :show-toolbar="false"
      :min-date="minDate"
      :max-date="maxDate"
      ref="datePicker"
      
    />
    <!-- 操作按钮 -->
    <div class="btn-groups">
        <VanButton color="#954EFF" class="btn" @click="handlePopClose">取消</VanButton>
        <VanButton color="#EF08E4" class="btn" @click="handleConfirm">确认</VanButton>
    </div>
  </div>

</van-popup>


</template>

<script>
export default {
name:'',
data() {
  return {
    show: true,
    minDate: new Date(1990, 0, 1),
    maxDate: new Date(2025, 10, 1),
    currentDate: new Date()
  }
},
methods: {
  closePop() {
    this.$emit('close')
  },
  handlePopClose() {
    this.$emit('close')
    this.show = false
  },
  handleConfirm() {
    let dateTime = this.$refs.datePicker.getPicker().getValues()
    let dateTimeStr = dateTime[0]+'-'+dateTime[1]+'-'+dateTime[2]
    this.$emit('getSelected', dateTimeStr)
    this.show = false
  }
},
mounted() {

}
}
</script>


<style lang="scss" scoped>
.picker-wrap {
  margin: 20px 0;

  .picker-title {
    text-align: center;
    font-size: 16px;
    font-weight: blod;
    font-weight: bold;
    margin-bottom: 15px;
  }
}

/deep/.van-picker__columns {
  background: #453847;
  padding: 10px 0;
}
/deep/.van-ellipsis {
  color: #fff;
}

/deep/.van-picker__mask {
  background-image: none;
}

.btn-groups {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .btn {
    width: 45%;
    height: 35px;
  }
}

</style>